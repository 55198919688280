export const greekLabels = {
  //FRONTEND FILE FOR FRONTEND TRANSLATIONS

  //Auth
  VERIFICATION_CODE_SENT: "Ένας κωδικός επαλήθευσης έχει σταλεί στο email σου",
  FORGOT_PASSWORD: "Ξέχασες τον κωδικό σου;",
  CONFIRM: "Επιβεβαίωση",
  WRITE_YOUR_EMAIL: "Γράψε το email σου",
  CREATE_NEW_ACCOUNT: "Δημιουργία λογαριασμού",
  SIGNUP: "Εγγραφή",
  PASSWORD: "Κωδικός",

  CLARIFICATION_PARAGRAPH:
    "Το email σου το χρησιμοποιούμε μόνο για τη δημιουργία λογαριασμού στην εφαρμογή και για την ενημέρωσή σου σχετικά με τις υπηρεσίες που προσφέρουμε.",
  DEFENDED_BY: "Οι χρήστες της εφαρμογής μας προστατεύονται από τους",
  GOOGLE_REGULATIONS: "κανονισμούς της Google. ",
  EU_USERS:
    "Περαιτέρω, οι χρήστες της Ευρωπαϊκής Ένωσης και Ηνωμένων Εθνών της Αμερικής προστατεύονται επιπλέον",
  FROM_THESE_REGULATIONS: "από αυτούς τους νόμους",

  TERMS_OF_USE: "Όροι χρήσης",

  PRIVACY_POLICY: "Πολιτική Απορρήτου και Όροι Παροχής Υπηρεσιών (Στα Αγγλικά)",
  LEGALITY_PARAGRAPH:
    "Η εφαρμογή του Δήμου Θεσσαλονίκης εγγυάται ασφάλεια στους χρήστες της συμμορφώνοντας την πλατφόρμα με παγκόσμια αναγνωρισμένους όρους και προϋποθέσεις παροχής υπηρεσιών της Ευρωπαϊκής Ένωσης, εξωτερικών οργανισμών και υπηρεσιών (όπως Google). Οι πολιτικές και όροι χρήσης παρέχονται μόνο στα αγγλικά προς το παρόν λόγω κανονισμών εξωτερικών υπηρεσιών της εφαρμογής.",
  SELECT_ICON_FOR_MORE_INFO:
    "Επίλέξε ένα από τα εικονίδια για να μεταφερθείς στο εξωτερικό έγγραφο που αναφέρει τις παραπάνω πληροφορίες.",

  //MapDeckGl

  LOADING_TREES: "Ο χάρτης με τα δέντρα φορτώνει 🌳",
  ADOPT_IT: "Υιοθέτησέ το!",
  CONGRATULATIONS: "Συγχαρητήρια! Υιοθέτησες ένα δέντρο!",
  ADOPTED_TREES: "Υιοθετημένα Δέντρα",
  AVAILABLE_TREES: "Διαθέσιμα Δέντρα",
  GO_THERE: "Αναζήτηση Ταχυδρομικού Κώδικα",
  NOT_AVAILABLE_TREES: "Μη Διαθέσιμα Δέντρα",
  FILTER: "Φιλτράρισμα",
  ZOOM_POSTAL: "Εύρεση TK",
  ZOOM_TO_ALL_TREES: "Εστίαση στα δέντρα σου",
  CANCEL_MSG: "Ακύρωση",
  SUCCESSFUL_GEOLOCATION: "Επιτυχία εντοπισμού θέσης",
  FAILED_GEOLOCATION:
    "Δεν υποστηρίζεται ο εντοπισμός θέσης από τον browser σου.",

  // TutorialModal
  INFO_MODAL_HEADER: "Πληροφορίες",
  INFO_MODAL_TREE_INFO: "Βλέπεις το χάρτη της πόλης με τα δέντρα της.",
  INFO_MODAL_MATURE_TREES:
    "Οι πράσινες μικρές κουκίδες είναι τα ενήλικα δέντρα.",
  INFO_MODAL_YOUNG_TREES:
    "Οι μπλε κουκίδες είναι τα νεαρά δέντρα προς υιοθεσία.",
  INFO_MODAL_USER_ADOPTED_TREES:
    "Οι ροζ κουκίδες είναι τα νεαρά δέντρα που έχεις ήδη υιοθετήσει.",
  INFO_MODAL_OTHER_ADOPTED_TREES:
    "Οι γκρι κουκίδες είναι τα νεαρά δέντρα που έχουν υιοθετήσει άλλοι χρήστες.",
  INFO_MODAL_QUESTION_ADOPT_TREE: "Θέλεις να υιοθετήσεις ένα δέντρο;",
  INFO_MODAL_QUESTION_ADOPT_TREE_TEXT:
    "Βρες στο χάρτη το δέντρο που θέλεις να υιοθετήσεις. Πάτησε στο χάρτη πάνω στην μπλε κουκίδα που αντιπροσωπεύει το δέντρο που θέλεις να υιοθετήσεις.  Θα εμφανιστεί ένα μήνυμα με τα στοιχεία του δέντρου που επίλεξες. Πάτησε το «Υιοθέτησε τώρα». Συγχαρητήρια. Υιοθέτησες ένα δέντρο! Η κουκίδα στο χάρτη που αντιπροσωπεύει το δέντρο που υιοθέτησες θα γίνει ροζ.",
  INFO_MODAL_QUESTION_WATER_TREE: "Θες να δηλώσεις ότι πότισες το δέντρο σου;",
  INFO_MODAL_QUESTION_WATER_TREE_TEXT:
    "Πάτησε στο χάρτη το ροζ δέντρο που μόλις υιοθέτησες και στη συνέχεια στο «Το Πότισα» που εμφανίζεται.",
  INFO_MODAL_DESCRIBE_ICONS: "Τι άλλο μπορείς να κάνεις στο χάρτη;",
  INFO_MODAL_DESCRIBE_ICONS_LOCATE_TREES:
    "Εμφανίζει όλα τα δέντρα που υιοθέτησες.",
  INFO_MODAL_DESCRIBE_ICONS_SEE_INSTRUCTIONS: "Εμφανίζει τις οδηγίες.",
  INFO_MODAL_DESCRIBE_ICONS_FIND_TREES:
    "Προβάλλει στο χάρτη την τοποθεσία των δέντρων που υιοθέτησες. Πάτησε ξανά αν έχεις υιοθετήσει δέντρα σε διαφορετικές περιοχές.",
  INFO_MODAL_DESCRIBE_ICONS_FIND_TREE_BASED_ON_POSTAL_CODE:
    "Προβάλλει στον χάρτη την περιοχή του ΤΚ που συμπλήρωσες.",
  INFO_MODAL_DESCRIBE_ICONS_FIND_TREE_BASED_ON_LOCATION:
    "Προβάλλει στο χάρτη την τοποθεσία που βρίσκεσαι αυτή τη στιγμή.",

  //NavLinks
  TREE_MAP: "Χάρτης δέντρων",
  LOGIN: "Σύνδεση",
  LEGALITY: "Νομιμότητα",
  CONTACT_US: "Επικοινωνία",
  DEVELOPERS: "Δημιουργοί",

  //RestPassword
  SUCCESSFUL_PASSWORD_CHANGE: "Ο κωδικός άλλαξε με επιτυχία.",
  FAILED_PASSWORD_CHANGE: "Η αλλαγή κωδικού δεν ήταν επιτυχής.",

  //Settings
  RECEIVE_EMAIL_FOR_PASSWORD_RESET:
    "Θα σου αποσταλεί email για την αλλαγή κωδικού",
  ACCOUNT_SETTINGS: "Ρυθμίσεις Λογαριασμού",
  RESET_PASSWORD: "Αλλαγή κωδικού",
  CONFIRM_RESET: "Αλλαγή",
  DELETE_ACCOUNT: "Διαγραφή Λογαριασμού",
  CONFIRM_DELETE: "Διαγραφή",
  DISCONNECT: "Αποσύνδεση",
  DELETE_ACCOUNT_QUESTION:
    "Αν διαγράψεις τον λογαριασμό σου θα χάσεις τα δέντρα που έχεις υιοθετήσει. Θέλεις να προχωρήσεις στη διαγραφή; Κλείσε το παράθυρο αν το μετάνιωσες.",
  PASSWORD_CHANGE_QUESTION:
    "Επιβεβαιώνεις ότι θέλεις να  αλλάξεις τον κωδικό σου; Κλείσε το παράθυρο αν το μετάνιωσες.",
  SUCCESSFUL_ACCOUNT_DELETION: "Η διαγραφή του λογαριασμού ήταν επιτυχής",
  SUCCESSFUL_REQUEST_RESET_PASSWORD:
    "Σύντομα θα λάβεις ένα email με οδηγίες για την αλλαγή του κωδικού σου",

  //TreeItem
  TODAY: "Σήμερα",
  YESTERDAY: "Χθες",
  DAYS_BEFORE: " μέρες πριν",
  NOT_WATERED: "Δεν έχει ποτιστεί",
  TYPE: "Είδος:",
  LAST_WATERED: "Ποτίστηκε τελευταία:",
  LATIN_NAME: "Λατινική ονομασία",
  FLOWERING: "Ανθοφορία",
  MAX_HEIGHT: "Μέγιστο ύψος",
  MAX_CROWN_DIAMETER: "Μέγιστη διάμετρος κόμης",

  NO_INFO_ON_TYPE: "Δεν υπάρχουν πληροφορίες για τον τύπο του δέντρου.",

  //TreeDeleteModal
  DELETE_FROM_MY_TREES: "Αφαίρεση από τα δέντρα μου",
  TREE_DELETED: "Το δέντρο αφαιρέθηκε από τα δέντρα σου",

  //TreeRenameModal
  SUCCESSFUL_TREE_RENAME: "Το δέντρο μετονομάστηκε επιτυχώς",

  //TreeWaterModal
  ARE_YOU_SURE_MSG: "Είσαι σίγουρος;",
  WATER_TREE: "Το πότισα!",
  TREE_WATERED_SUCCESSFULLY: "Η αναφορά ποτίσματος καταχωρήθηκε.",
  WATERED_RECENTLY: "Ποτίστηκε πρόσφατα",

  //UserTrees
  MY_TREES: "Τα δέντρα μου",

  //Verify

  SUCCESSFUL_EMAIL_VERIFICATION:
    "Η επαλήθευση της διεύθυνσης email ήταν επιτυχής.",
  FAILED_EMAIL_VERIFICATION:
    "Η επαλήθευση της διεύθυνσης email δυστυχώς δεν ήταν επιτυχής.",
  YOU_WILL_BE_REDIRECTED_SHORTLY:
    "Θα ανακατευθυνθείς σύντομα στην αρχική σελίδα.",

  GO_TO_LOGIN: "Έχεις ήδη λογαριασμό;",
  GO_TO_SIGNUP: "Δεν έχεις λογαριασμό;",
  EMAIL_ADDRESS: "Διεύθυνση email",
  INVALID_EMAIL_ADDRESS: "Μη έγκυρη διεύθυνση email",
  REQUIRED_FIELD: "Υποχρεωτικό πεδίο",

  NEW_PASSWORD: "Καινούριος κωδικός",
  REPEAT_NEW_PASSWORD: "Επανάληψη καινούριου κωδικού",
  USE_PROPER_PASSWORD: "Ο κωδικός πρέπει να είναι οχτώ χαρακτήρες.",
  PASSWORDS_MUST_MATCH: "Οι κωδικοί πρέπει να είναι ίδιοι.",

  RENAME: "Μετονομασία",
  ADOPT_A_TREE: "Υιοθέτησε ένα δέντρο",

  RATE_LIMITED:
    "Η υπηρεσία δεν είναι προσωρινά διαθέσιμη. Προσπάθησε ξανά αργότερα.",

  MARK_AS_READ: "Σημείωσε ως διαβασμένα",
  notifications: {
    unwateredTrees: {
      message:
        "Κάποια δέντρα σου παραμένουν απότιστα. Τα νεαρά δέντρα χρειάζονται περίπου 3-4 λίτρα κάθε μέρα, ανάλογα με την εποχή. Μπορείς να ποτίζεις καθημερινά ή 2 με 3 φορές την εβδομάδα, το πρωί ή ιδιαίτερα σε περιόδους έντονης ζέστης, το βράδυ.",
      header: "Κάποια δέντρα σου παραμένουν απότιστα",
    },
  },

  home: {
    welcomeMessage:
      "Καλωσήρθες στην ψηφιακή εφαρμογή υιοθεσίας δέντρων «ΠοτίΖΩ»!",
    learnMore: "Μάθε περισσότερα",
    adoptNow: "Υιοθέτησε τώρα",
    appDescription:
      "Έλα και εσύ στην ψηφιακή εφαρμογή του 'ΠοτίΖΩ' του Δήμου Θεσσαλονίκης για να υιοθετήσεις από ένα έως τρία δέντρα εντοπίζοντάς τα στον χάρτη, στη γειτονιά που επιθυμείς.",
    waterYourTree:
      "Ποτίζεις το δέντρο σου, προστατεύεις τη φύση και βελτιώνεις το περιβάλλον που ζεις!",
    whyAdoptTree: "Γιατί να υιοθετήσω ένα δέντρο;",
    youngTreeSurvival1: "Εξασφαλίζεις την επιβίωση των νεαρών δέντρων.",
    youngTreeSurvival2:
      "Τα δέντρα προς υιοθεσία είναι νέα δέντρα και χρειάζονται καθημερινή φροντίδα.",
    environmentShaping1: "Διαμορφώνεις το περιβάλλον εκεί που ζεις.",
    environmentShaping2:
      "Επιλέγεις στον χάρτη την τοποθεσία του δέντρου που θα υιοθετήσεις. Έξω από το σπίτι σου, το μαγαζί σου, το σχολείο σου; Εσύ διαλέγεις!",
    protectYourEnvironment1: "Προστατεύεις το περιβάλλον που ανήκεις.",
    protectYourEnvironment2:
      "Τα δέντρα μάς παρέχουν καθαρό αέρα, δροσιά και βοηθούν στη σωματική και ψυχική μας υγεία.",
    beautifyYourDay1: "Ομορφαίνεις την κάθε σου μέρα.",
    beautifyYourDay2:
      "Το χρώμα, η αίσθηση της φύσης, το πράσινο γεμίζουν το περιβάλλον γύρω σου με θετική ενέργεια.",
    provideShelter1: "Δίνεις καταφύγιο​ ​σε ​άλλα ζώα.",
    provideShelter2:
      " Ένα δέντρο συντηρεί ένα πλήθος πουλιών και εντόμων που συμβάλλουν στη βιοποικιλότητα του περιβάλλοντος.",
    betterFuture:
      "​Βάζεις το λιθαράκι σου για ένα καλύτερο μέλλον σε μια πιο πράσινη πόλη.",
    beVolunteer:
      "Γίνεσαι εθελοντής σε μία μεγάλη, συλλογική προσπάθεια και συμβάλλεις ενεργά στον στόχο επίτευξης της κλιματικής ουδετερότητας της πόλης μας μέχρι το 2030.",
    treeAdoptionResponsibilities:
      "Τι υποχρεώσεις έχω εάν υιοθετήσω ένα δέντρο;",
    waterYourTreeDescription:
      "Ποτίζεις το δέντρο που διάλεξες στην περιοχή που έχεις επιλέξει.",
    wateringInstructions:
      "Οδηγίες ποτίσματος: Τα νεαρά δέντρα χρειάζονται περίπου 3-4 λίτρα κάθε μέρα, ανάλογα με την εποχή. Μπορείς να ποτίζεις καθημερινά ή 2 με 3 φορές την εβδομάδα, το πρωί ή ιδιαίτερα σε περιόδους έντονης ζέστης, το βράδυ.",
    notifyWatering: "Ενημερώνεις μέσω της ψηφιακής εφαρμογής για το πότισμα.",
    howToAdoptTree: "Πώς μπορώ να υιοθετήσω ένα δέντρο;",
    adoptTreeDescription:
      "Πατάς το «Υιοθέτησε τώρα» και συμπληρώνεις τα απαραίτητα στοιχεία στη φόρμα εγγραφής.",
    chooseTreesHeading: "Επίλεξε τα δέντρα σου",
    chooseTreesDescription:
      "Επιλέγεις από το χάρτη της πόλης, τα δέντρα (μέχρι 3) που θέλεις να ποτίζεις.",
    congratulationsHeading: "Συγχαρητήρια",
    congratulationsText:
      "Έχεις υιοθετήσει το δέντρο σου! Είσαι εθελοντής στο πρόγραμμα “ΠοτίΖω”. Ένα μεγάλο ευχαριστώ!",
    learnMoreQuestion: "Θέλεις να μάθεις ακόμη περισσότερα;",
    aboutCreatorsHeading: "Ποιοί είναι οι δημιουργοί της εφαρμογής 'ΠοτίΖΩ';",
    aboutCreatorsText:
      "Η ψηφιακή εφαρμογή «ΠοτίΖΩ» αναπτύχθηκε για το Δήμο Θεσσαλονίκης από ομάδες φοιτητών του τμήματος Πληροφορικής του CITY College, University of York Europe Campus στη Θεσσαλονίκη.",
    contactUsHeading: "Θέλεις να επικοινωνήσεις μαζί μας;",
    contactUsText:
      "Eίναι χαρά μας να σε ακούσουμε! Στείλε μας email στο …………………….. Για περισσότερους τρόπους επικοινωνίας, δες εδώ.",
  },
};
